/* eslint-disable indent, no-undef, no-redeclare, no-unused-vars, quotes */
/*
File: statistics.js

Description:
    Statistics and helper functions for visual budget application

Requires:
    d3.js

Authors:
    Ivan DiLernia <ivan@goinvo.com>
    Roger Zhu <roger@goinvo.com>

License:
    Copyright 2013, Involution Studios <http://goinvo.com>

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
*/

stats = {
    amount: {
        title: "مقدار",
        class: "span6 top",
        value: function (d) {
            return formatcurrency(d.values[yearIndex].val);
        },
        side: function () {
            return " in " + (avb.firstYear + yearIndex).toString() + ".";
        },
        cellClass: "value sum ",
        cellFunction: function (d, cell) {
            avb.table.renderAmount(d, cell);
        }
    },
    // impact: {
    //     title: "Impact",
    //     class: "span6 ",
    //     value: function (d) {
    //         return Math.max(0.01, (Math.round(d.values[yearIndex].val * 100 * 100 / avb.root.values[yearIndex].val) / 100)).toString() + "%";
    //     },
    //     side: function () {
    //         return " of total " + avb.section + ".";
    //     },
    //     cardRenderer : function(d, cell){
    //         $(cell).html(Mustache.render($('#card-template').html(),this));
    //         if(this.value(d) === '100%') {
    //             $(cell).find('.card').css({ display : 'none'});
    //         }
    //     },
    //     cellClass: "value sum",
    //     cellFunction: function (d, cell) {
    //         avb.table.renderImpact(d, cell);
    //     }
    // },
    // individual: {
    //     title: "Individual",
    //     class: "span6 individual",
    //     value: function (d) {
    //         var percentage = d.values[yearIndex].val / avb.root.values[yearIndex].val;

    //         return '$' + (avb.userContribution * percentage).toFixed(2);
    //     },
    //     side: 'your yearly tax contribution.',
    //     cellClass: "value sum",
    //     cellFunction: function (d, cell) {
    //         avb.table.renderImpact(d, cell);
    //     }
    // },
    percentAmount: {
        title: "درصد",
        class: "percent",
        value: function (d) {
            var percent = 100 * d.values[yearIndex].val / avb.root.values[yearIndex].val;
            percent = '%' + toPersianNum(percent.toFixed(2));
            return percent;
        },
        cellClass: "value percent textleft"
    },
    growth: {
        title: "Growth",
        class: "span6 top",
        value: function (d) {
            return growth(d);
        },
        side: " compared to previous year.",
        cellFunction: function (d, cell) {
            avb.table.renderGrowth(d, cell);
        },
        cellClass: "value"
    },
    source: {
        title: "Source",
        class: "info info-box card-source info-slider",
        value: function (d) {
            return (d.src === '') ? longName : toPersianNum(d.src);
        },
        // link: function (d) {
            // return (d.url === '') ? sourceURL : d.url;
        // },
        cardRenderer : function(d, card){
            $card = $(card);
            $card.html(Mustache.render($('#card-template').html(), this));

            // $card.attr('onclick', "window.location='" + this.link(d)  + "'");
                // prevent sliding animation
                // $card.click(function(event){
                    // stop propagation
                // stopPropagation(window.event || event);
            // });
        },
        side: "<svg><use xlink:href='/img/icons-d41d8cd98f.svg#source'></use></svg> منبع داده‌ها"
    },
    // mean: {
    //     title: "Average",
    //     class: "span6 ",
    //     value: function (d) {
    //         return formatcurrency(d3.mean(d.values, function(d) { return d.val; }));
    //     },
    //     side: "on average."
    // },
    moreinfo: {
        title: "More Info",
        class: "info info-box more-info info-slider",
        value: function (d) {
            return d.moreinfo;
        },
        cardRenderer : function(d, card){
            $card = $(card);

            if (!d.moreinfo.length == 0) {
                $card.html(Mustache.render($('#card-template').html(), this));
            } else {
                $('.more-info').hide();
            }
        },
        side: "<svg><use xlink:href='/img/icons-d41d8cd98f.svg#moreinfo'></use></svg> اطلاعات بیشتر"
    },
    filler: {
        title: "",
        class: "span6 ",
        value: function (d) {
            return "";
        },
        side: ""
    },
    name: {
        title: "عنوان",
        cellClass: "value name long",
        value: function (d) {
            return d.key;
        }
    },
    sparkline: {
        title: "Change",
        cellClass: "value sparkline",
        cellFunction: function (d, cell) {
            avb.table.renderSparkline(d, cell);
        }
    },
    section : {
        title: "بودجه",
        cellClass: "value section",
        value: function (d){
            return d.section;
        }
    },
    year : {
        title: "سال",
        cellClass: "value year",
        value: function (d){
            return toPersianNum(d.values[0].year);
        }
    },
    parent : {
        title : "بخش",
        cellClass: "value parent",
        value: function (d){
            return (typeof(d.parent) === 'string') ? d.parent : 'هیچ یک';
        }
    },
    mapLink : {
        title : "",
        cellClass: "maplink",
        cellFunction: function (d, cell) {
            avb.table.renderMaplink(d, cell);
        }
    }
};

decks = {
    // revenues: [stats.amount, stats.growth, stats.impact, stats.mean, stats.source],
    revenues1397: [stats.source, stats.moreinfo],
    expenses1397: [stats.source, stats.moreinfo],
    revenues1396: [stats.source, stats.moreinfo],
    expenses1396: [stats.source, stats.moreinfo],
    revenues1395: [stats.source, stats.moreinfo],
    expenses1395: [stats.source, stats.moreinfo],
};

tables = {
    // revenues: [stats.name, stats.growth, stats.sparkline, stats.impact, stats.amount, stats.mapLink],
    revenues1397: [stats.mapLink, stats.name, stats.amount, stats.percentAmount],
    revenues1396: [stats.mapLink, stats.name, stats.amount, stats.percentAmount],
    revenues1395: [stats.mapLink, stats.name, stats.amount, stats.percentAmount],
    expenses1397: [stats.mapLink, stats.name, stats.amount, stats.percentAmount],
    expenses1396: [stats.mapLink, stats.name, stats.amount, stats.percentAmount],
    expenses1395: [stats.mapLink, stats.name, stats.amount, stats.percentAmount],
    // search: [stats.name, stats.growth, stats.sparkline, stats.amount, stats.parent,  stats.section, stats.mapLink]
    search: [stats.mapLink, stats.name, stats.amount, stats.parent, stats.year, stats.section]
};

/*
*   Formats currency
*
*   @param {float/int} value - number to be formatted
*   @return {string} formatted value
*/
function formatcurrency(value) {
    if (value === undefined) {
        return "N/A";
    } else if (value >= 1000000) {
        // return "$" + Math.round(value / 1000000).toString() + " M";
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else if (value < 1000000 && value >= 1000) {
        // return "$" + Math.round(value / 1000).toString() + " K";
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else if (value < 1 && value != 0) {
        // return "¢" + Math.round(value * 100).toString();
        return (value * 1).toString();
    } else {
        // return "$ " + value.toString();
        return value.toString();
    }
}

/*
*   Formats currency with no rounding
*
*   @param {float/int} value - number to be formatted
*   @return {string} formatted value
*/
function formatCurrencyExact(value) {
    var commasFormatter = d3.format(",.0f");
    return "$ " + commasFormatter(value);
}

/*
*   Formats percentage
*
*   @param {float/int} value - number to be formatted
*   @return {string} formatted value
*/
function formatPercentage(value) {
    if (value > 0) {
        return "+ " + value.toString() + "%";
    } else if (value < 0) {
        return "- " + Math.abs(value).toString() + "%";
    } else {
        return Math.abs(value).toString() + "%";
    }
}

/*
*   Calculates growth (% change) compared to previous datapoint
*
*   @param {node} data - node for which growth has to be computed
*   @return {string} - growth in %
*/
function growth(data) {
    var previous = (data.values[yearIndex - 1] !== undefined) ? data.values[yearIndex - 1].val : 0;
    var perc = Math.round(100 * 100 * (data.values[yearIndex].val - previous) / data.values[yearIndex].val) / 100;
    return formatPercentage(perc);
}

/* eslint-disable indent, no-undef, no-redeclare, no-unused-vars, quotes */
/*
File: treemap.js

Description:
    Treemap component for visual budget application.

Authors:
    Ivan DiLernia <ivan@goinvo.com>
    Roger Zhu <roger@goinvo.com>

License:
    Copyright 2013, Involution Studios <http://goinvo.com>

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
*/

// Determine client browser and version
navigator.sayswho = (function(){
    var ua= navigator.userAgent, tem,
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\bOPR\/(\d+)/);
        if(tem!= null) return 'Opera '+tem[1];
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
})();

// Boolean - is browser IE?
function ie(){
    var agent = navigator.sayswho;
    var reg = /IE\s?(\d+)(?:\.(\d+))?/i;
    var matches = agent.match(reg);
    if (matches != null) {
        return true;
    }
    return false;
}

var avb = avb || {};

avb.treemap = function () {
    var nav, currentLevel,
        // holds rgb values for white
        white = {
            r: 255,
            b: 255,
            g: 255
        };

    /*
    *   Initialize navigation treemap
    *
    *   @param {jquery selection} $container - treemap container
    *   @param {node} data - root node that will become root level of treemap
    */
    function initialize($container, data) {
        var width = $container.width(),
            height = $container.height();

        var height = height,
            formatNumber = d3.format(",d"),
            transitioning;

        // create svg
        nav = d3.select($container.get(0)).append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .style("shape-rendering", "crispEdges");

        // initialize x and y scales
        nav.x = d3.scale.linear()
            .domain([0, width])
            .range([0, width]);

        nav.y = d3.scale.linear()
            .domain([0, height])
            .range([0, height]);

        nav.h = height;
        nav.w = width;

        // color scale
        // nav.color = d3.scale.category20();

        // Custom colors for Expense and Revenue treemaps
        d3.scale.categoryRev = function() {
            return d3.scale.ordinal().range(d3_categoryRev);
        };
        var d3_categoryRev = [ "#0E5D74", "#15738E", "#3192AD", "#4AA9C4" ];

        d3.scale.categoryExp = function() {
            return d3.scale.ordinal().range(d3_categoryExp);
        };
        var d3_categoryExp = [ "#951245", "#B41653", "#D93474", "#E56194" ];

        if (avb.section.indexOf('exp') >= 0) {
            nav.color = d3.scale.categoryExp();
            data.colorRange = d3_categoryExp;
        } else {
            // nav.color = d3.scale.category20();
            nav.color = d3.scale.categoryRev();
            data.colorRange = d3_categoryRev;
        }

        // center zoom button vertically
        $('#zoombutton').center();

        // initialize chart
        // avb.chart.initialize($('#chart'));

        avb.currentNode.data = data;

        // start populating treemap
        update(data);

    }

    /*
    *   Computes treemap layout for a nested dataset.
    *   Treemap data will be stored within each node object.
    *
    *   @param {node} data - node where treemap should begin
    */
    function update(data) {
        // remove all old treemap elements
        nav.selectAll("g").remove();

        // this function recursively determines
        // treemap layout structure
        var layout = function (d) {
            // root color
            d.color = nav.color(0);

            if (d.sub) {
                treemap.nodes({
                    values: d.values,
                    children: d.sub
                });
                d.sub.forEach(function (c,i) {
                    c.x = d.x + c.x * d.dx;
                    c.y = d.y + c.y * d.dy;
                    c.dx *= d.dx;
                    c.dy *= d.dy;
                    c.parent = d;
                    layout(c);
                    // node color
                    c.color = nav.color(i);
                });
            }
        };

        // initialize treemap
        var init = function (root) {
            root.x = root.y = 0;
            root.dx = nav.w;
            root.dy = nav.h;
            root.depth = 0;
        };

        // create treemap d3 layout
        var treemap = d3.layout.treemap()
        // node children
        .children(function (d, depth) {
            return depth ? null : d.children;
        })
        // treemap values calculated based on current year value
        .value(function (d) {
            return d.values[yearIndex].val;
        })
        // block sorting function
        .sort(function (a, b) {
            return a.values[yearIndex].val - b.values[yearIndex].val;
        })
            .ratio(nav.h / nav.w * 0.5 * (1 + Math.sqrt(5)))
            .round(false);

        var root = data;

        nav.grandparent = nav.append("g")
            .attr("class", "grandparent");

        // init treemap
        init(root);
        // init layout
        layout(root);

        // display treemap
        currentLevel = display(avb.currentNode.data);
    }

    /*
    *   Draws and displays a treemap layout from node data
    *
    *   @param {node} d - node where treemap begins (root)
    */
    function display(d) {

        // remove all popovers
        $('.no-value').popover('destroy');

        var formatNumber = d3.format(",d"),
            // flag will be used to avoid overlapping transitions
            transitioning;

        // return block name
        function name(d) {
            return d.parent ? name(d.parent) + "." + d.key : d.key;
        }

        // insert top-level blocks
        var g1 = nav.insert("g", ".grandparent")
            .datum(d)
            .attr("class", "depth")
            .on("click", function (event) {
                zoneClick.call(this, d3.select(this).datum(), true);
            });

        // add in data
        var g = g1.selectAll("g")
            .data((d.sub.length === 0) ? [d] : d.sub)
            .enter().append("g");

        // create grandparent bar at top
        nav.grandparent
            .datum((d.parent === undefined) ? d : d.parent)
            .attr("nodeid", (d.parent === undefined) ? d.hash : d.parent.hash)
            .on("click", function (event) {
                zoneClick.call(this, d3.select(this).datum(), true);
            });

        // refresh title
        updateTitle(d);

        /* transition on child click */
        g.filter(function (d) {
            return d.sub;
        })
            .classed("children", true)
            // expand when clicked
            .on("click", function (event) {
                zoneClick.call(this, d3.select(this).datum(), true);
            })
            .each(function () {
                var node = d3.select(this);
                // assign node hash attribute
                node.attr('nodeid', function () {
                    return node.datum().hash;
                });
            });

        // draw parent rectange
        g.append("rect")
            .attr("class", "parent")
            .call(rect)
            .style("fill", function (d) {
                return applyTransparency(d.color, 0.8);
            });

        // var rects = $('g rect.parent');
        // var colorRange = d.colorRange;

        // Loop through colorRange and get values without duplicating unless there are more than 4 rectangles
        // function randomColors(colorRange) {
        //     var copy = colorRange.slice(0);
        //     return function() {
        //         if (copy.length < 1) {
        //             copy = colorRange.slice(0);
        //         }
        //         var index = Math.floor(Math.random() * copy.length);
        //         var item = copy[index];
        //         copy.splice(index, 1);
        //         return item;
        //     };
        // }
        // var rectFill = randomColors(colorRange);

        // rects.each(function () {
        //     // var rectFill = d.colorRange[Math.floor(Math.random() * d.colorRange.length)];
        //     $(this).css("fill", rectFill);
        // });

        // recursively draw children rectangles
        function addChilds(d, g) {
            // add child rectangles
            g.selectAll(".child")
                .data(function (d) {
                    return d.sub || [d];
                })
                .enter().append("g")
                .attr("class", "child")

            // propagate recursively to next depth
            .each(function () {
                var group = d3.select(this);
                if (d.sub !== undefined) {
                    $.each(d.sub, function () {
                        addChilds(this, group);
                    });
                }
            })
                .append("rect")
                .call(rect);
        }

        addChilds(d, g);

        // IE popover action
        if (ie()) {
            nav.on('mouseout', function () {
                d3.select('#ie-popover').style('display', 'none');
            });
            return g;
        }

        // assign label through foreign object
        // foreignobjects allows the use of divs and
        // textwrapping
        g.each(function () {
            var label = d3.select(this).append("foreignObject")
                .call(rect)
                .attr("class", "foreignobj")
                .append("xhtml:div")
                .html(function (d) {
                    var title = '<div class="titleLabel">' + d.key + '</div>',
                        // convert values to curreny to include commas
                        currencyValues = formatcurrency(d.values[yearIndex].val);
                        // values = '<div class="valueLabel">' + d.values[yearIndex].val + '</div>';
                        // format currency value to Persian
                        values = '<div class="valueLabel">' + toPersianNum(currencyValues) + ' <span class="unitLabel">میلیارد تومان</span></div>';
                    return title + values;
                })
                .attr("class", "textdiv");

            textLabels.call(this);

        });

        return g;

    }

    /*
    *   Assigns label and popover events (IE only)
    *   IE9 does not support SVG foreign objects
    *
    *   @param {node} d - node object to which popover has to be attached
    */
    function ieLabels(d) {

        /*
        * Attach popover event to zone
        */
        function attachPopoverIe(obj, title, descr) {
            var thisPopover, popoverWidth, popoverHeight, arrow, popoverText, popoverTitle;
            thisPopover = d3.select('#ie-popover');
            arrow = thisPopover.select('.arrow');
            popoverTitle = thisPopover.select('.popover-title');
            popoverText = thisPopover.select('.text');
            d3.select(obj).on('mouseover', function () {
                var rect = d3.select(this).select('.parent');
                var coords = [parseFloat(rect.attr('x')),
                    parseFloat(rect.attr('y'))
                ];
                popoverTitle.text(title);
                if (descr !== null) { popoverText.text(descr); }
                popoverWidth = $('#ie-popover').width();
                popoverHeight = $('#ie-popover').outerHeight();
                if (coords[0] > 500 || coords[1] < 10) {
                var x = coords[0] - (popoverWidth + 8);
                var y = coords[1] + (parseFloat(rect.attr('height')) / 2) - (popoverHeight / 2);
                var arrowX = (popoverWidth - 5).px();
                var arrowY = ((popoverHeight/2) - 5).px();
                arrow.style('-ms-transform', 'rotate(-90deg)');
                } else {
                var x = coords[0] + (parseFloat(rect.attr('width')) / 2) - (popoverWidth / 2);
                var y = coords[1] - (popoverHeight + 8);
                var arrowX = ((popoverWidth / 2) - 5).px();
                var arrowY = popoverHeight.px();
                }
                thisPopover
                .style('display', 'block')
                .style('left', (x).px())
                .style('top', (y).px());
                arrow
                .style('left', arrowX)
                .style('top', arrowY);
            })
            .on('mouseout', function () {
            popoverTitle.text('');
            popoverText.text('');
            popoverWidth = 0;
            popoverHeight = 0;
            arrow.style('-ms-transform', 'none');
            });
        }

        // label zone using svg:text object
        var label = d3.select(this).append("text")
            .call(rect).attr('dy', '1.5em').attr('dx', '0.5em')
            // assign label name
            .text(function (d) {
                return d.key;
            });
        textLabels.call(this);

        var d = d3.select(this).datum(),
            containerHeight = nav.y(d.y + d.dy) - nav.y(d.y),
            containerWidth = nav.x(d.x + d.dx) - nav.x(d.x);

        // do not show label if zone is too small
        if (containerHeight < 40 || containerWidth < 150) {
            d3.select(this).classed("no-label", true);
            popover = true;
        }

        var description = d.descr;
        // if (avb.userContribution != null && avb.section == 'expenses') {
        //     // popover content is split in separate 2 divs
        //     description = d.descr + ' Your contribution is ' + stats.individual.value(d);
        // } else {
        //     description = d.descr;
        // }

        // attach popover to zone
        attachPopoverIe(this, d.key, description);
    }

    /*
    *   Assigns label and popover events (Chrome, Safari, FF)
    *
    *   @param {node} d - node to be labelled
    */
    function textLabels(d) {

        /*
        * Attach popover event to zone
        * Requires bootstrap popovers
        */
        function attachPopover(obj, title, descr) {
            $(obj).find('div').first().popover({
                container: 'body',
                trigger: 'hover',
                placement: function (context, source) {

                    // calculate best position for popover placement
                    // offset is used instead of position due to firefox + svg bug
                    var position = $(source).offset();
                    if (position.top < 200) {
                        return "right";
                    } else {
                        return "top";
                    }
                },
                title: (descr !== '' && d.title !== '') ? d.key : '',
                content: (descr !== '') ? descr : d.key,
                html: true
            });
        }

        var d = d3.select(this).datum(),
            containerHeight = nav.y(d.y + d.dy) - nav.y(d.y),
            containerWidth = nav.x(d.x + d.dx) - nav.x(d.x),
            title = $(this).find('.titleLabel').first(),
            div = $(this).find('.textdiv').first();

        // eliminate old popover and reset zone classes
        // $(this).find('div').first().popover('destroy');
        $(this).find('div').popover('destroy');
        // no-label -> zone is too small to show any text at all
        d3.select(this).classed("no-label", false);
        // no-label -> zobe is too small to show amount label
        d3.select(this).classed("no-value", false);
        // compensates padding
        var labelPadding = 16;
        div.height(Math.max(0, containerHeight - labelPadding));

        // every entry has no popover by default
        var popover = false;

        // Note.
        // If we are in the expenses section and the user did enter his/her
        // tax contribution, popovers will be used to show how much each
        // zone amounts in terms of personal contribution.
        var description;
        if (avb.userContribution != null && avb.section == 'expenses') {
            // popover content is split in separate 2 divs
            // description = '<div>' + d.descr + '</div> <div class="contribution"> Your contribution is ' + stats.individual.value(d) + '</div>';
            // description = '<div>' + d.descr + '</div> <div class="contribution"> Percentage ' + d.percent + '</div>';
            description = '<div>' + toPersianNum(d.descr) + '</div>';
        } else {
            description = toPersianNum(d.descr);
        }

        // calculate whether zone has enough space for any labels
        // if (containerHeight < title.outerHeight() || containerHeight < 40 || containerWidth < 60) {
        if (containerHeight < title.outerHeight() || containerHeight < 75 || containerWidth < 115) {
            d3.select(this).classed("no-label", true);
            popover = true;
        }
        // calculate whether zone has enough space for amount label
        // if (containerHeight < div.height() || containerHeight < 80 || containerWidth < 90) {
        if (containerHeight < div.height() || containerHeight < 150 || containerWidth < 90 ) {
            d3.select(this).classed("no-value", true);
        }
        // attach popover to zone
        if (popover || description !== '' || containerWidth < 80) {
            attachPopover(this, d.key, description);
        }


    }

    /*
    *   Updates page title when sections
    *
    *   @param {node} data - current node
    */
    function updateTitle(data) {
        var $title = $(".title-head .text");
        var $titleBlock = $("#information-cards .title-head");
        var $zoom = $('#zoombutton');
        var parent = d3.select('.grandparent').node();
        var $des = $("#information-cards .description");
        var $mobileDes = $(".mobile-header .description");

        // remove previous action set on zoom button
        $zoom.unbind();
        // set title text
        $title.text(data.key);
        // Update data key to Farsi text
        if (data.key.indexOf('Rev') >= 0) {
            data.key = 'درآمد‌ها';
        } else if (data.key.indexOf('Exp') >= 0) {
            data.key = 'هزینه‌ها';
        } else {
            // do nothing
        }
        // Wrap span tag around information container title
        $title.wrapInner('<span class="info-title"></span>');

        // make sure to shrink text if it does not fit
        // 48px is max text size
        $title.textfill(48, $('.title-head').width() - 120);

        // main section such as revenues, expenses and funds need to have descriptions
        // if ($.inArray(data.key.toLowerCase(), avb.sections) > -1) {
            // $('<div class="description">  </div>').appendTo($title).html(data.descr);
        // }
        $des.html(data.descr);
        $mobileDes.html(data.descr);

        // make zoom-out button appear disabled while at root nodes
        if (avb.currentNode.data === avb.root) {
            $zoom.addClass('disabled');
        } else {
            $zoom.removeClass('disabled');
        }

        // zoom button renders parent zone
        if(data !== avb.root) {
            $zoom.click(function () {
                zoneClick.call(parent, d3.select(parent).datum(), true);
            });
        }

    }

    /*
    *   Displays node in treemap
    *
    *   @param {string} nodeId - hash that refers to zone
    *   @param {integer} transition - duration of transition from current node to destination node (optional)
    */
    function open(nodeId, transition) {
        // find node with given hash or open root node
        zoneClick.call(null, findHash(nodeId, avb.root) || avb.root, false, transition || 1);
    }

    /*
    *   Event triggered on click event in treemap areas
    *
    *   @param {node} d - clicked node data
    *   @param {boolean} click - whether click was triggered
    *   @param {integer} transition - transition duration
    */
    function zoneClick(d, click, transition) {
        //destroy popovers on transition (so they don't accidentally stay)
        // $(this).find('div').first().popover('destroy');
        $(this).find('div').popover('destroy');

        // Clear treemap popovers that accidently stay
        $('html').click(function() {
            $('div.popover').css('display', 'none');
        });

        // Hide description box
        $('#information-cards .description').addClass('hidden');

        // stop event propagation
        var event = window.event || event;
        stopPropagation( event );

        transition = transition || 750;

        // do not expand if another transition is happening
        // or data not defined
        if (nav.transitioning || !d) return;

        // go back if click happened on the same zone
        if (click && d.hash === avb.currentNode.data.hash) {
            $('#zoombutton').trigger('click');
            return;
        }

        // push url to browser history
        if (click) {
            pushUrl(avb.section, avb.thisYear, avb.mode, d.hash);
        }

        // Dynamically change social buttons URL as treemap URL
        var currentURL = window.location.protocol + "//" + window.location.host + window.location.pathname;
        var twitterSection;
        if (avb.section.indexOf('exp') >= 0) {
            twitterSection = 'اعتبارات';
        } else {
            twitterSection = 'درآمدهای ردیف';
        }
        $('.twitter-share').attr('href', 'https://twitter.com/intent/tweet?text=' + twitterSection + ' "' + d.key + '" در سال ' + avb.thisYear + ' را در سایت «ایران بودجه» ببینید.&url=' + currentURL + '&via=' + twitterVar);
        $('.fb-share').attr("href", "https://www.facebook.com/sharer/sharer.php?u=" + currentURL);
        $('.telegram-share').attr("href", "https://telegram.me/share/url?url=" + currentURL);

        // copy link to hidden input field and display conformation for 5 seconds
        var linkBtn = document.querySelector('.link-share');
        var interval;
        linkBtn.addEventListener('click', function() {
            clearTimeout(interval);
            var dummy = $('<input class="dummyInput">').val(currentURL).appendTo('body').select();
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? $('.message-success').removeClass('hidden') : $('.message-failed').removeClass('hidden');
                // $('.message').append('Link ' + msg);
            } catch (err) {
                $('.message-failed').removeClass('hidden');
            }
            settimeout();
        });

        function settimeout(){
            interval=setTimeout(function(){
                // console.log("Idle for 5s");
                $('.dummyInput').remove();
                $('.msg').addClass('hidden');
            },5000);
        }

        // reset year
        yearIndex = avb.thisYear - avb.firstYear;

        //
        if(d.values[yearIndex].val === 0) {
            zoneClick.call(null, d.parent || avb.root.hash);
            return;
        }

        // remove old labels
        nav.selectAll('text').remove();

        // remember currently selected section and year
        avb.currentNode.data = d;
        avb.currentNode.year = yearIndex;

        // update chart and cards
        // avb.chart.open(d, d.color);
        avb.cards.open(d);

        // prevent further events from happening while transitioning
        nav.transitioning = true;

        // initialize transitions
        var g2 = display(d);
        t1 = currentLevel.transition().duration(transition),
        t2 = g2.transition().duration(transition);

        // Update the domain only after entering new elements.
        nav.x.domain([d.x, d.x + d.dx]);
        nav.y.domain([d.y, d.y + d.dy]);

        // Enable anti-aliasing during the transition.
        nav.style("shape-rendering", null);

        // Draw child nodes on top of parent nodes.
        nav.selectAll(".depth").sort(function (a, b) {
            return a.depth - b.depth;
        });

        // Fade-in entering text.
        g2.selectAll(".foreignobj").style("fill-opacity", 0);

        // Transition to the new view
        t1.style('opacity', 0);
        t1.selectAll(".foreignobj").call(rect);
        t2.selectAll(".foreignobj").call(rect);
        t1.selectAll("rect").call(rect);
        t2.selectAll("rect").call(rect);

        // add labels to new elements
        t2.each(function () {
            if (ie()) return;
            textLabels.call(this);
        });
        t2.each("end", function () {
            if (ie()) {
                ieLabels.call(this);
            } else {
                textLabels.call(this);
            }
        });

        // Remove the old node when the transition is finished.
        t1.remove().each("end", function () {
            nav.style("shape-rendering", "crispEdges");
            nav.transitioning = false;

        });
        // update current level
        currentLevel = g2;
    }

    /*
    *   Sets SVG rectangle properties based on treemap node values
    *
    *   @param {d3 selection} rect - SVG rectangle
    */
    function rect(rect) {
        rect.attr("x", function (d) {
            return nav.x(d.x);
        })
        .attr("y", function (d) {
            return nav.y(d.y);
        })
        .attr("width", function (d) {
            return nav.x(d.x + d.dx) - nav.x(d.x);
        })
        .attr("height", function (d) {
            return nav.y(d.y + d.dy) - nav.y(d.y);
        });
    }

    // $( window ).resize(function(){
    //     // avb.chart.initialize($('#chart'));
    //     // $('#navigation svg').css('width', '100%');
    //     // $('#navigation').css('height', 'auto');
    //     initialize();
    // });


    return {
        initialize: initialize,
        update: update,
        open: open,
        updateTitle: updateTitle
    };
}();

/* eslint-disable indent, no-undef, no-unused-vars, quotes */
/*
File: cards.js

Description:
    Card component for visual budget application

Requires:
    d3.js

Authors:
    Ivan DiLernia <ivan@goinvo.com>
    Roger Zhu <roger@goinvo.com>

License:
    Copyright 2013, Involution Studios <http://goinvo.com>

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
*/


var avb = avb || {};

avb.cards = function(){
    // all the cards that need to be shown for the current section
    var deck = [];
    // all card objects
    var cardstack = [];
    var $cards;

    /*
    *   Initializes cards
    */
    function initialize() {
        cardstack = [];
        $cards = $("#cards");
        // each section has its own deck, or information to be shown
        // about each entry
        // eg. only expenses has personal contribution card
        deck = decks[avb.section];

        var container,
            // rowHtml = '<div class="row-fluid card-row separator"> </div>';
            rowHtml = '<div class="row-fluid card-row"> </div>';
        // draw all cards in deck
        for(var i=0; i < deck.length; i++) {
            // append new row every 2 cards
            if (i%2 === 0) {
                container = $(rowHtml).appendTo($cards);
            }
            // creates div for new card
            var newcard = $('<div class="card-wrapper"></div>').appendTo(container);
            // var newcard = drawCard(container, deck[i]);
            // remember card object for future updates
            cardstack.push(newcard);
        }

        // Show/hide description box
        $('.desc-btn').click(function () {
            $(this).next('.description').toggleClass('hidden');
        });
        // close on body click
        $(document).on('click', function(){
            $('.description').addClass('hidden');
        });
        $('.desc-btn').on('click', function(e){
            e.stopPropagation();
        });
    }

    /*
    *   Updates all cards with latest data
    *
    *   @param {node} data - node for which data has to be displayed
    */
    function update(data) {

        // Calculate and display sidebar totals
        var sbKeys;

        if (avb.section.indexOf("rev") >= 0) {
            $('.node-bar.exp').hide();
            sbKeys = ['درآمدهای ملی', 'درآمدهای استانی', 'درآمدهای اختصاصی'];
        } else {
            $('.node-bar.rev').hide();
            sbKeys = ['هزینه‌های جاری', 'هزینه‌‌های عمرانی', 'اعتبارات مالی'];
        }

        // Add budget amount above Information column
        $('#information-cards #revenues-node, #navigation-container #revenues-mobile-node').find('.node-value').text(toPersianNum(formatcurrency(data.values[0].val)));
        $('#information-cards #expenses-node, #navigation-container #expenses-mobile-node').find('.node-value').text(toPersianNum(formatcurrency(data.values[0].val)));

        // Create new array for sidebar data - format string to number with 3 trailing decimal numbers
        var sb1Total = parseFloat(data.sb1.replace(/,/g, '')).toFixed(3);
        var sb2Total = parseFloat(data.sb2.replace(/,/g, '')).toFixed(3);
        var sb3Total = parseFloat(data.sb3.replace(/,/g, '')).toFixed(3);
        var sbTotals = [sb1Total, sb2Total, sb3Total];

        // Default to first color called in legendLabels
        var sbColors = data.color;

        // clean up old legend if needed
        d3.selectAll("#legend tr").remove();

        var rows = $('#legend tbody');

        for (var i = 0; i < sbKeys.length; i++) {
            var node = data.values[0].val;

            // Calculate percentage. For colored bars, set max width to 100%
            var percent = 100 * sbTotals[i] / node;
            if (percent > 100) {
                percent = 100.00;
            } else {
                percent = percent.toFixed(2);
            }

            sbTotals[i] = formatcurrency(sbTotals[i]);

            // Append row to table
            rows.append('<tr><td><div class="legend-label" style="background-color: ' + sbColors + '; width: ' + percent + '%;"></div></td><td>' + sbKeys[i] + '</td><td>' + toPersianNum(sbTotals[i]) + ' میلیارد تومان</td><td>(%' + toPersianNum(percent) + ')</td></tr>');
        } //end for loop

        // Generate csv data link for download
        var csvData = $('.data a');
        $(csvData).attr('href', '/data/' + avb.section + '.csv');

        // update all cards in deck
        $.each(deck, function(i,d){
            // render template
            if(typeof(d.cardRenderer) === 'function') {
                d.cardRenderer(data, cardstack[i]);
            } else {
                cardstack[i].html(Mustache.render($('#card-template').html(),d));
            }
            // set value
            cardstack[i].find(".card-value").html(deck[i].value(data));

            // set card description if available
            cardstack[i].find(".card-desc").html(
                (typeof(deck[i].side) === 'string') ? deck[i].side : deck[i].side(data));
        });

        // Hide info button if description hasn't been included for this year
        if ($('.description').text().length < 1){
            $('.desc-btn').hide();
        }

        // Get source link based on thisYear. Links defined in localized_variables.php
        // var sourceLink = pdfLinks.filter(function(pdfLink){
        var sourceLink = pdfLinks.find(function(pdfLink){
            return pdfLink.year == avb.thisYear;
        });

        // If there is a link for thisYear, wrap link around card-source and more-info boxes
        if (sourceLink) {
            $('.card-value').wrapInner('<a href="' + sourceLink.link + '"  target="_blank"></a>');
        }

        // Show 'more info' conatiner
        $('.info-slider .card-img').append('<span><svg><use xlink:href="/img/icons-d41d8cd98f.svg#arrow-down"></use></svg></span><span class="hidden"><svg><use xlink:href="/img/icons-d41d8cd98f.svg#arrow-up"></use></svg></span>');
        $('.info-slider').click(function(){
            $(this).children('.card-text').children('.card-value').slideToggle();
            $(this).children('.card-img').children('span').toggleClass('hidden');
        });
    }

    /*
    *   Displays node in cards
    *
    *   @param {node} data - node for which data has to be displayed
    */
    function open(data){
        avb.cards.update(data);
    }

    return {
        open : open,
        update : update,
        initialize : initialize
    };
}();

/* eslint-disable indent, no-undef, no-redeclare, no-unused-vars, quotes */
/*
File: table.js

Description:
    Table compoent for visual budget application

Authors:
    Ivan DiLernia <ivan@goinvo.com>
    Roger Zhu <roger@goinvo.com>

License:
    Copyright 2013, Involution Studios <http://goinvo.com>

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
*/
var avb = avb || {};

avb.table = function () {

    var indent; // indentation width
    if ($(window).width() < 800) {
        indent = 5;
    } else {
        indent = 25;
    }
    var tableStats = []; // columns to be shown
    // color scales
    // var growthScale = d3.scale.linear().clamp(true).domain([-10, 10]).range(["rgb(29,118,162)", 'rgb(167, 103, 108)']);
    // var amountScale = d3.scale.linear().clamp(true).range(["#aaa", "#333"]);
    // var impactScale = d3.scale.linear().clamp(true).domain([0, 100]).range(["#aaa", "#333"]);

    /*
    * Initializes table
    *
    *   @param {jQuery obj} $container - table container
    *   @param {node} data - nodes to be displayed
    */
    function initialize($container, data) {
        var $table = $container;

        // remove old rows
        $('.tablerow').remove();

        if (data instanceof Array) {

            /*
             * Data is flat (search results)
             */

            // load row template
            tableStats = tables.search;

            // display no results message should that be the case
            if (data.length === 0) {
                textRow('نتیجه‌ای پیدا نشد.', $table);
                $('.tablerow').addClass('empty');
                return;
            }
            // render table head
            $table.append(Mustache.render($('#table-header-template').html(), tableStats));

            // render all nodes (all search results)
            $.each(data, function () {
                renderNode(this, 0, $table);
            });
        } else {

            /*
             * Data in nested (datasets)
             */

            tableStats = tables[avb.section];

            // ensure table view doesn't have search class
            $('#avb-wrap').removeClass('search');

            // initialize scale
            // amountScale.domain([0, data.values[yearIndex].val * 0.5]);
            // render root node (cascades to children)
            renderNode(data, 0, $table).trigger('click');

            // Render data above table
            $('.table-title').find('.info-title').text(data.key);

            // Update data key to Farsi text
            var tableTitle = $('.table-title').find('.info-title');
            if (avb.section.indexOf("Rev") >= 0) {
                tableTitle.replaceWith('<span class="info-title">درآمد‌ها<span>');
                $('.table-title #expenses-node').find('.node-value').text(toPersianNum(formatcurrency(data.values[0].val)));
                $('.table-title #expenses-node').show();
            } else {
                tableTitle.replaceWith('<span class="info-title">هزینه‌ها<span>');
                $('.table-title #revenues-node').find('.node-value').text(toPersianNum(formatcurrency(data.values[0].val)));
                $('.table-title #revenues-node').show();
            }
        }
    }

    /*
    *   Aligns columns when the indentation level changes
    */
    function alignRows() {
        // could do this using a stack
        var maxLevel = 0;
        // find maximum depth level
        $('.tablerow').each(function () {
            if ($(this).data('level') > maxLevel) maxLevel = $(this).data('level');
        });

        // assign each first column a margin-right so that all the remaining
        // columns will be aligned
        // $('.tablerow').each(function () {
        //     $(this).find('.name').animate({
        //         'margin-left': (maxLevel - $(this).data('level')) * 0
        //     }, 250);
        // });
    }

    /*
    *   Renders row containing just text (used to display 'No results found' msg)
    *
    *   @param {string} msg - message to be displayed
    *   @param {jQuery obj} table - container
    */
    function textRow(msg, $table) {
        var template = $('#row-template');
        var rendered = $table.append(Mustache.render(template.html())).children().last();
        // align text to center
        rendered.css({
            'text-align': 'center'
        }).text(msg);
    }

    /*
    *   Click event for rows
    */
    function rowClick() {
        var row = $(this);
        var node = row.data();

        // change arrow direction
        row.children('.child-bullet').toggleClass('hidden');

        // atomic nodes don't need to expand or collapse
        if (row.hasClass('atomic')) return;

        if (row.hasClass('expanded')) {

            // collapse and automatically rendered rows and remove generated classes
            if (row.hasClass('lvl1')) {
                $(this).next('group').children('tablerow').removeClass('expanded');
                $(this).next('group').children('group').css('display','none');
                $(this).next('group').css('display','none');
            }

            if (row.hasClass('top')) {
                $('.group').children('tablerow').removeClass('expanded');
                $('.group').children('group').css('display','none');
            }

            /*
             *  Collapse row if expanded
             */

            // retrieve children rows
            var child = row.data('childDiv');

            // fix for rows expanded on load not reading correct child element
            if (!child) {
                child = row.next('.group');
            }

            // slide up children rows
            child.slideUp(250, function () {
                $(this).remove();
                alignRows();
            });

            row.removeClass('expanded');

        } else {

            /*
             *  Expand row is collapsed
             */

            // container
            var childDiv = $('<div class="group"></div>').insertAfter(row);

            // Check if table is loading at the root or from a sub level
            var newHash = window.location.pathname.split('/')[4];
            var q;

            for (var i = 0; i < node.sub.length; i++) {

                // Query for a sub level with newHash value
                q = findByHash(node.sub[i], newHash);

                // Render children if hash found in URL. Check that this loop only runs on initial load and not as rows expand/collapse
                if ( (q) && !($('#table-container').hasClass('loaded')) ) {
                    // Lvl 1 - top level
                    renderNode(node.sub[i], row.data('level') + 1, childDiv);
                    $('.tablerow').last().addClass(node.sub[i].hash);
                    row.data('childDiv', childDiv);
                    $('.tablerow').last().addClass('expanded lvl1');
                    // Lvl 2
                    for (var j = 0; j < node.sub[i].sub.length; j++) {
                        renderNode(node.sub[i].sub[j], row.data('level') + 2, childDiv);
                        $('.tablerow').last().addClass('lvl2 ' + node.sub[i].sub[j].hash);
                        row.data('childDiv', childDiv);

                        if (node.sub[i].hash != newHash) {
                            // Lvl 3
                            for (var k = 0; k < node.sub[i].sub[j].sub.length; k++) {
                                q = findByHash(node.sub[i].sub[j], newHash);
                                if (q) {
                                    // tag lvl 2 parent
                                    // $('.lvl2').last().addClass('expanded').after('<div class="group"></div>');
                                    $('.lvl2').last().addClass('expanded');
                                    // render children
                                    renderNode(node.sub[i].sub[j].sub[k], row.data('level') + 3, childDiv);
                                    $('.tablerow').last().addClass('lvl3 ' + node.sub[i].sub[j].sub[k].hash);
                                    row.data('childDiv', childDiv);
                                }

                                if (node.sub[i].sub[j].hash != newHash) {
                                    // Lvl 4
                                    for (var l = 0; l < node.sub[i].sub[j].sub[k].sub.length; l++) {
                                        q = findByHash(node.sub[i].sub[j].sub[k], newHash);
                                        if (q) {
                                            // tag lvl 3 parent
                                            $('.lvl3').last().addClass('expanded');
                                            renderNode(node.sub[i].sub[j].sub[k].sub[l], row.data('level') + 4, childDiv);
                                            $('.tablerow').last().addClass('lvl4 ' + node.sub[i].sub[j].sub[k].sub[l].hash);
                                            row.data('childDiv', childDiv);
                                        }

                                        if (node.sub[i].sub[j].sub[k].hash != newHash) {
                                            // Lvl 5
                                            for (var m = 0; m < node.sub[i].sub[j].sub[k].sub[l].sub.length; m++) {
                                                q = findByHash(node.sub[i].sub[j].sub[k].sub[l], newHash);
                                                if (q) {
                                                    $('.lvl4').last().addClass('expanded');
                                                    renderNode(node.sub[i].sub[j].sub[k].sub[l].sub[m], row.data('level') + 5, childDiv);
                                                    $('.tablerow').last().addClass('lvl5 ' + node.sub[i].sub[j].sub[k].sub[l].sub[m].hash);
                                                    row.data('childDiv', childDiv);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // add ID to row matching hash in URL
                        $('.tablerow.' + newHash).attr('id', 'newHashRow');
                    }
                    // toggle +/- icons for all expanded rows
                    $('.expanded').children('.child-bullet').toggleClass('hidden');

                    // wrap rendered child rows in a container div
                    $('.lvl2').wrapAll('<div class="group" style="display: block;" />');
                    $('.lvl3').wrapAll('<div class="group lvl3" style="display: block;" />');
                    $('.lvl4').wrapAll('<div class="group lvl4" style="display: block;" />');
                    $('.lvl5').wrapAll('<div class="group lvl5" style="display: block;" />');

                    // Render lvl depths inside of parent group
                    $('.group.lvl3').insertAfter('.tablerow.lvl2.expanded');
                    $('.group.lvl4').insertAfter('.tablerow.lvl3.expanded');
                    $('.group.lvl5').insertAfter('.tablerow.lvl4.expanded');

                    // scroll to row that matches hash in table
                    setTimeout(function(){
                        if ($('#newHashRow').length > 0) {
                            if ($(window).width() < 1160) {
                                $('html, body').animate({
                                    scrollTop: $('#newHashRow').offset().top-55
                                }, 1000);
                            } else {
                                $('html, body').animate({
                                    scrollTop: $('#newHashRow').offset().top
                                }, 1000);
                            }
                        }
                    }, 100);

                    // confirm that initial render is complete
                    $('#table-container').addClass('loaded');
                } else {
                    // Render top-level data normally
                    renderNode(node.sub[i], row.data('level') + 1, childDiv);
                    row.data('childDiv', childDiv);
                }
            }

            alignRows();
            childDiv.slideDown(250);

            // remove loading animation when loading complete
            $('.loader').hide();

            row.addClass('expanded');
        }
    }

    /*
    *   Renders row based on node data
    *
    *   @param {object} node - current node
    *   @param {int} level - current depth
    *   @param {jquery object} - container to which new row is appended
    *
    *   @return {jquery object} - new row
    */
    function renderNode(node, level, container) {
        // append row to container
        var template = $('#row-template');
        var rendered = container.append(Mustache.render(template.html(), node)).children().last();

        // check whether node has children
        rendered.addClass((node.sub === undefined || node.sub.length === 0) ? 'atomic' : '');

        // disable expanding on search rows
        $('#avb-wrap.search .tablerow').addClass('atomic');

        rendered.data(node);
        // $('#table-container').fadeIn('slow', function()
        // $('.tablerow').fadeIn(slow);
        rendered.data('level', level);

        // recreate indentation style based on level
        if (level > 0) {
            rendered.css({
                'padding-right': level * indent
            });
        }

        var newcell;
        $.each(tableStats, function () {
            // append new cell to row
            newcell = $('<div class="' + this.cellClass + '"> </div>').appendTo(rendered);
            if (this.cellFunction) {
                // function (eg. formatting numerical value)
                this.cellFunction(node, newcell.get(0));
            } else {
                // text (eg. row title)
                newcell.text(this.value(node));
            }
        });

        $('.group .tablerow .sum').each(function () {
            // Adjust margins for 'sum' column when row is indented.
            var rowPadding = $(this).parent().css('padding-right').split('px');
            var sumMargin = parseInt(rowPadding[0]) / 2;
            $(this).css({'margin-left': sumMargin + 'px', 'margin-right': '-' + sumMargin + 'px'});
        });

        // Update section titles to Farsi for search results.
        $('.tablerow .section').each(function () {
            if ($(this)[0].innerHTML.indexOf("Rev") >= 0) {
                $(this).replaceWith('<div class="value section rev">درآمد‌ها<div>');
            }
            else if ($(this)[0].innerHTML.indexOf("Exp") >= 0) {
                $(this).replaceWith('<div class="value section exp">هزینه‌ها<div>');
            } else {
                // do nothing - header row
            }
        });

        // Update parent data if results = Expenses or Revenues
        $('.tablerow .parent').each(function () {
            if ($(this)[0].innerHTML.indexOf("Rev") >= 0) {
                $(this).replaceWith('<div class="value parent">درآمد‌ها<div>');
            } else if ($(this)[0].innerHTML.indexOf("Exp") >= 0) {
                $(this).replaceWith('<div class="value parent">هزینه‌ها<div>');
            } else {
                // do nothing
            }
        });

        // Update name data if results = Expenses or Revenues
        $('.tablerow .name').each(function () {
            if ($(this)[0].innerHTML.indexOf("Rev") >= 0) {
                $(this).replaceWith('<div class="value name long">درآمد‌ها<div>');
            } else if ($(this)[0].innerHTML.indexOf("Exp") >= 0) {
                $(this).replaceWith('<div class="value name long">هزینه‌ها<div>');
            } else {
                // do nothing
            }
        });

        // append popover
        if (node.descr.length !== 0) {
            rendered.find('.long').popover({
                // trigger : 'hover' is not the best solution
                // as it will show the popover mid-row, which
                // what we want
                trigger: 'manual',
                animation : false,
                // calculate best position for popover placement
                placement: function (context, source) {
                    var position = $(source).position();
                    if (position.top < 150) {
                        return "bottom";
                    } else {
                        return "top";
                    }
                },
                // assign popover content
                content: node.descr
            });
        }
        // show popover on hover
        rendered.mouseenter(function () {
            rendered.find('.long').popover('show');
        });
        rendered.mouseleave(function () {
            rendered.find('.long').popover('hide');
        });

        // attach click event
        rendered.click(rowClick);

        return rendered;
    }

    /*
    *   Draws amount cell for current node
    *
    *   @param {object} data - current node
    *   @param {jquery object} - current cell
    */
    function renderAmount(data, cell) {
        var amount = (data.values[yearIndex].val);
        // apply color based on scale
        // if (tableStats !== tables.search) $(cell).css({
        //     "color": amountScale(amount)
        // });
        // format numeric value
        $(cell).text(toPersianNum(formatcurrency(amount)));
        // $(cell).text(formatcurrency(amount));
        $(cell).append('<span class="unit">میلیارد تومان</span>');
    }

    /*
    *   Draws inpact cell for current node
    *
    *   @param {object} data - current node
    *   @param {jquery object} - current cell
    */
    // function renderImpact(data, cell) {
    //     var impact = stats.impact.value(data);
    //     // apply color based on scale
    //     $(cell).css({
    //         "color": impactScale(impact)
    //     });
    //     $(cell).text(impact);
    // }

    /*
    *   Draws links that redirect to treemap representation of current entry
    *
    *   @param {object} data - current node
    *   @param {jquery object} - current cell
    */
    function renderMaplink(data, cell){
        // Define year and section values for search mapLinks
        if (data.section != undefined){
            avb.section = data.section.toLowerCase();
        }

        $(cell).html('<div class="bullet"><svg><use xlink:href="/img/icons-d41d8cd98f.svg#arrow-left"></use></svg></div>');

        $(cell).click(function(){
            // avb.section = findSection(data.hash).key.toLowerCase();

            // Update year based on result clicked so that correct url is generated
            avb.thisYear = data.values[0].year;
            avb.section = avb.section.split('1')[0] + avb.thisYear;

            switchMode('t', true);
            // give enough time to load data
            setTimeout(function(){
                avb.treemap.open(data.hash, false, false);
            }, 50);

            // update nav links and selected dropdown year as page loads
            $('.menu .expense a').attr('href', '/expenses' + avb.thisYear);
            $('.menu .revenue a').attr('href', '/revenues' + avb.thisYear);
            $('#yeardrop option[value=' + avb.thisYear + ']').attr('selected','selected');

            // update selected element in nav
            $('.navbar-container span').removeClass('selected');
            if (avb.section.indexOf('exp') >= 0) {
                $('.navbar-container span.expense').addClass('selected');
            } else {
                $('.navbar-container span.revenue').addClass('selected');
            }
        });
    }

    function open() {}

    /*
    *   Updates/re-renders table rows
    */
    function update() {
        // update all rows
        $('.tablerow').each(function () {
            var node = $(this);

            // do not update table header
            if (node.is('#table-header')) return;

            // assumption. cell order and tableStats array do not change
            // update all cells
            for (var i = 0; i < tableStats.length; i++) {
                var cell = $($(node).find('.value').get(i));
                // refresh cell value
                if (tableStats[i].cellFunction) {
                    // function (eg. formatting numerical value)
                    tableStats[i].cellFunction(node.data(), cell.get(0));
                } else {
                    // text (eg. row title)
                    cell.text(tableStats[i].value(node));
                }
            }
        });
    }

    return {
        initialize: initialize,
        renderAmount: renderAmount,
        // renderImpact: renderImpact,
        renderMaplink : renderMaplink,
        open: open,
        update: update
    };
}();

/* eslint-disable indent, no-undef, no-redeclare, no-unused-vars, quotes */
/*
File: navbar.js

Description:
    Navigation bar compoent for visual budget application

Authors:
    Ivan DiLernia <ivan@goinvo.com>
    Roger Zhu <roger@goinvo.com>

License:
    Copyright 2013, Involution Studios <http://goinvo.com>

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
*/

var avb = avb || {};

avb.navbar = function(){

    /*
    *	Input change event
    */
    function searchChange() {
        var  keyword = $(this).val();

        // displays search results
        function showResults(){
            // switch to table view
            if(avb.navigation !== avb.table){
                setMode('l');
            }

            if ($('#avb-home').is(':visible')) {
                $('#overlay, #avb-home').hide();
            }

            // allow to go back - Go back is disabled since map button is hidden
            // pushUrl(avb.section, avb.thisYear, 'l', avb.root.hash);

            // show search results
            avb.navigation.initialize($('#avb-wrap'), search(keyword));
        }

        // have a 300ms timeout from the time the user
        // stops typing
        clearTimeout(timer);
        // timer = setTimeout( showResults, 300);
        timer = setTimeout( showResults, 500);
    }

    /*
    *	Searches all datasets for keywords
    *
    *	@param {string} keyword - keyword to be searched
    *	@return {array} - array containing all matched nodes
    */
    function search(keyword) {
        var result = [];

        // Hide tab button
        $('.title-head').addClass('hidden');
        $('#avb-wrap').addClass('search');

        // aggregate search results from all sections
        $.each(avb.sections, function(){
            var searchSection = this;
            var newResult = searchObject(keyword, avb.data[this], avb.data);
            // remember where searched element was found
            $.each(newResult, function() { this.section = capitalize(searchSection); });
            // result = result.concat(newResult);

            if (keyword === ''){
                // prevent it from loading all the data and crashing
                return false;
            } else {
                result = result.concat(newResult);
            }
        });
        return result;
    }

    /*
    *	Recursively searches dataset
    *
    *	@param {string} keyword - keyword to be searched
    *	@param {object} object - object to be searched
    *	@param {object} parent - object parent
    *
    */
    function searchObject(keyword, object, parent) {
        var index = object.key.toLowerCase().indexOf(keyword.toLowerCase());

        // Index tooltip and source data for search
        // var indexSource = object.src.toLowerCase().indexOf(keyword);
        // var indexTooltip = object.descr.indexOf(keyword);

        // ignore matches in mid word
        if (index !== 0 && object.key[index-1] !== ' ') index = -1;
        // each matched object has to report its parent name
        if(index != -1) { object.parent = parent.key; }
        // results
        var result = index !== -1 ? [object] : [];

        // break down results by category so that everything isn't being processed at the same time
        // var result;
        // if ((index !== -1) ) {
        //     result = [object];
        // } else if (indexSource !== -1) {
        //     result = [object];
        // } else if (indexTooltip !== -1) {
        //     result = [object];
        // } else {
        //     result = [];
        // }

        // propagate recursively
        if(object.sub !== undefined) {
            // propagate to all children
            for(var i=0; i<object.sub.length; i++) {
                // aggregate children results
                result = result.concat(searchObject(keyword, object.sub[i], object));
            }
        }
        return result;
    }

    /*
    *	Removes right-handside portion of navbar
    *	Used in pages that do not have a map/table interactions
    *	(glossary, data...)
    */
    // function minimize() {
    //     $('#navbar-links .entry').last().remove();
    // }

    /*
    *	Initialize navigation bar
    */
    function initialize() {
        // year dropdown (non-mobile browsers)
        $dropdown = $('#yeardrop-container');
        $dropdownLabel = $('#yeardrop-label');
        $dropdownList = $('#yeardrop-list');

        // year selector (mobile browsers)
        $selector = $('#yeardrop-container-mobile');

        // Hide home and overlay once results begin to display
        if ($('#avb-home').is(':visible')) {
            $('#overlay, #avb-home').hide();
        }

        // hide homepage when search box is selected
        $('#searchbox').bind('click touchstart',function () {
            if ($('#avb-home').is(":visible")) avb.home.hide();
        });
    }

return{
    initialize : initialize,
    searchChange : searchChange,
    // minimize : minimize
};
}();

/* eslint-disable indent, no-undef, no-redeclare, no-unused-vars, quotes */
/*
File: home.js

Description:
    Homepage component for visual budget application

Authors:
    Ivan DiLernia <ivan@goinvo.com>
    Roger Zhu <roger@goinvo.com>

License:
    Copyright 2013, Involution Studios <http://goinvo.com>

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
*/
var avb = avb || {};

avb.home = function () {
    var home = {};

    /*
    *   Initiialize function
    */
    function init() {

        /*
        *   hides overlay when clicked
        *   defaults to funds section
        */
        function overlayClick(event) {
            event.stopPropagation();
            // highlight 'funds' link
            // $($('.section').get(2)).addClass('selected');
            //highlight expense section
            // $($('.section').get(3)).addClass('selected');
            hide();
        }

        // homepage div
        home.content = $('#avb-home');
        //overlay init
        home.overlay = $('#overlay');
        home.overlay.click(overlayClick);
        // visualization init
        home.map = $('#home-map-svg');
        home.menubar = $('#avb-menubar');

        $('.section').removeAttr('onclick');
    }

    function sectionClick() {
        initializeVisualizations({
            section: $(this).attr('data-section').toLowerCase()
        });
        // home page minimizes right after treemap values are calculated
        // avoids sloppy animations
        setTimeout(function () { hide(); }, 100);
    }

    /*
    *   Shows home page
    */
    function show() {
        // delays where inserted to mitigate jumps
        // on page load due to web-fonts loading and changing
        // the page aspect

        // fade in body
        // $('#avb-body').css({opacity : 0});
        // $('#avb-body').delay(100).animate({opacity : 1});

        // Hide or add vertical scroll on window resize
        if (($( window ).width() < 1160) || ($( window ).height() > 700)) {
            $('body').css('overflow', 'hidden');
        }

        home.overlay.show();

        // fade in homepage content
        // home.content.delay(300).fadeIn();
        home.content.delay(25).fadeIn();

        // show section bar animation
        var data = JSON.parse($('#data-home').html());
        // setTimeout(function () {
        //     home.data = data;
        //     showGraph(1000);
        // }, 1500);

        // start application
        initializeVisualizations({
            "section": "expenses" + latestYear
        });

        // do not highlight any sections while homepage is open
        $('.section').removeClass('selected');

        // Add yeardrop class to year dropdown on homepage
        $('.yeardrop').addClass('year-home');

        var nodeData = data.sub;
        $('#revenues-node').find('.node-value').text(toPersianNum(formatcurrency(nodeData[0].values[0].val)));
        $('#expenses-node').find('.node-value').text(toPersianNum(formatcurrency(nodeData[1].values[0].val)));
    }

    return {
        initialize: init,
        show: show,
    };
}();

/* eslint-disable indent, no-undef, no-unused-vars, quotes */
/*
File: avb.js

Description:
    Visual budget application main routines

Requires:
    d3.js

Authors:
    Ivan DiLernia <ivan@goinvo.com>
    Roger Zhu <roger@goinvo.com>

License:
    Copyright 2013, Involution Studios <http://goinvo.com>

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
*/
var avb = avb || {};

// navigation variables

avb.root = null; // reference to root node of current section
avb.section = null; // current selected section
avb.mode = null; // current mode (map, table etc.)
avb.data = {}; // json data
avb.currentNode = {}; // currently selected node

// time variables

// first datapoint
avb.firstYear = null;
// last datapoint
avb.lastYear = null;
// avb.currentYear = new Date().getFullYear();
// avb.thisYear = avb.currentYear;
// Manually add first year in data - Framework defaults to current year
avb.thisYear = latestYear;

// amount of yearly taxes spent by user
avb.userContribution = null;
// available data sections
avb.sections = [
    'revenues1397',
    'expenses1397',
    'revenues1396',
    'expenses1396',
    'revenues1395',
    'expenses1395'
];
section = avb.section;

// available modes (treemap, table..)
avb.modes =
{
    "l" : {
        js : avb.table,
        template : '#table-template',
        container : '#table-container'
    },
    "t" : {
        js : avb.treemap,
        template : '#treemap-template',
        container : '#navigation'
    }
};

var timer = 0;

// Protoypes

/*
* Converts number to css compatible value
*/
Number.prototype.px = function () {
    return this.toString() + "px";
};

/*
*   Reads parameters from current url path and calls related
*   initialization routines
*/
function initialize(){
    var urlComponents = window.location.pathname.substring(1).split('/');
    var params = {
        section : urlComponents[0],
        year : urlComponents[1],
        mode : urlComponents[2],
        node : urlComponents[3]
    };
    avb.navbar.initialize();
    if(params.section === undefined || params.section === "") {
        avb.home.initialize();
        avb.home.show();
    } else if($.inArray(params.section, avb.sections) > -1){
        initializeVisualizations(params);
    } else {
        // avb.navbar.minimize();
    }
}

/*
*  Initializes data visualization components
*
*  @param {obj} params - year, mode, section and node
*/
function initializeVisualizations(params) {
    // get previosly set year
    var yearCookie = parseInt(jQuery.cookie('year'));
    // use year listed in the params object
    if (params.year !== undefined && !isNaN(parseInt(params.year))) {
        avb.thisYear = params.year;
    // use year previosly set (if any)
    } else if (!isNaN(yearCookie)) {
        avb.thisYear = yearCookie;
    } else {
        // do nothing
    }
    avb.section = params.section;

    // get user contribution if set
    // avb.userContribution = avb.home.getContribution();

    // set viewing mode
    setMode(params.mode);

    // connect search actions
    $('#searchbox').keyup(avb.navbar.searchChange);

    loadData();
}

// Function to search for hash if loading a sub level in treemap view
function findByHash(o, hash) {
    if( o.hash === hash ) {
        return o;
    }
    var result, p;
    for (p in o) {
        if( o.hasOwnProperty(p) && typeof o[p] === 'object' ) {
            result = findByHash(o[p], hash);
            if(result){
                return result;
            }
        }
    }
    return result;
}

/*
*   Parses JSON files and calls visualization subroutines
*/
function loadData() {
    // get datasets
    // loads all jsons in data
    $.each(avb.sections, function (i, url) {
        avb.data[url] = JSON.parse($('#data-' + url).html());
    });

    // initialize root level
    avb.root = avb.data[avb.section];

    // inialize year variables based on data
    avb.thisYear = avb.root.values[0].year;
    yearCookie = avb.thisYear;

    // determine oldest year
    avb.firstYear = d3.min(avb.root.values, function (d) {
        return d.year;
    });
    // determine newest year
    avb.lastYear = d3.max(avb.root.values, function (d) {
        return d.year;
    });
    yearIndex = avb.thisYear - avb.firstYear;
    avb.navbar.initialize(avb.thisYear);

    avb.currentNode.data = undefined;

    // initialize cards
    avb.cards.initialize();

    // load treemap at proper depth if hash is found in the URL
    var newHash = window.location.pathname.split('/')[4];

    if ((typeof newHash === 'undefined') || (newHash === avb.root.hash) || (avb.mode === "l")){
        // open normally at root level
        avb.navigation.initialize($(avb.modes[avb.mode].container), avb.root);
        avb.navigation.open(avb.root.hash);
    } else if (avb.root.hash != newHash) {
        // Search through object data to find matching hash value.
        var newRoot = findByHash(avb.root.sub, newHash);

        avb.navigation.initialize($(avb.modes[avb.mode].container), avb.root);
        // if hash is found, keep root data but open at newHash level
        if (newRoot) {
            avb.treemap.open(newRoot.hash, false, false);
        } else {
            // newRoot not found. Open at top level.
            avb.treemap.open(avb.root.hash, false, false);
        }
    }


    // Custom functions for initial load and state changes
    var nav = $('#navigation');
    if (avb.section.indexOf('rev') >= 0){
        // highlight current selection in navigation bar
        $('.menu .revenue').addClass('selected');
        // Add classes to sections to add styling
        nav.addClass('rev');
    } else {
        $('.menu .expense').addClass('selected');
        nav.addClass('exp');
    }

    // Change popover background based on Revenue or Expense sections
    var color = avb.root.color;
    nav.mouseover(
        function () {
            var popover = $('.popover .popover-title');
            popover.css('background-color', color);
        }
    );

    // Set treemap popover background color based on color of block selected
    // $('#navigation g.children').mouseover(
    // // $('#navigation').mouseover(
    //     function () {
    //         var popover = $('.popover .popover-title');
    //         var el = this.querySelector('.children');
    //         // var el = d3.select('.children rect').node();
    //         var rect = this.querySelector('rect');
    //         var color = rect.style.fill;
    //         // console.log(el[0].style.fill);
    //         popover.css('background-color', color);
    //     }
    // );

    console.log("UI Loaded.");
}

/*
*   Browser history routines
*   (Chrome, Safari, FF)
*/

/*
*   Back button action
*/
window.onpopstate = popUrl;

/*
*   Pushes current status to browser history
*
*   @param {string} section - current section
*   @param {int} year - current year
*   @param {string} mode - treemap or table view
*   @param {string} node - hash of current node
*
*/
function pushUrl(section, year, mode, node) {
    if (ie()) return;
    // format URL
    var url = '/' + section + '/' + year + '/' + mode + '/' + node;
    // create history object
    window.history.pushState({
        section: section,
        year: avb.thisYear,
        mode: mode,
        nodeId: node
    }, "", url);
}

/*
*   Restores previous history state
*
*   @param {state obj} event - object containing previous state
*/
function popUrl(event) {
    if (ie()) return;

    if (event.state === null) {
        avb.navigation.open(avb.root.hash, 500);
    } else if (event.state.mode !== avb.mode) {
        switchMode(event.state.mode, false);
    } else {
        avb.navigation.open(event.state.nodeId, 500);
    }
}


/*
*   Mode selection subroutines
*/

/*
*   Sets visualization mode
*
*   @param {string} mode - 'l' for list, 't' for treemap
*/
function setMode(mode) {
    var $container = $('#avb-wrap');
    mode = mode || "t";
    avb.mode = mode;
    avb.navigation = avb.modes[mode].js;
    $container.html(Mustache.render($(avb.modes[mode].template).html()));
}

/*
* Switches between visualization models
*
* @param {string} mode - visualization mode ('l' for list, 't' for treemap)
* @param {bool} pushurl - whether to push change in browser history
*/
function switchMode(mode, pushurl) {
    if (pushurl === undefined) pushurl = true;
    setMode(mode);

    var newHash = window.location.pathname.split('/')[4];

    // Maintain hash while switching between treemap and table view.
    if (pushurl) {
        if ((typeof newHash === 'undefined') || (newHash === avb.root.hash) || (mode == "t")){
            // open at root level
        } else {
            // push URL with updated hash to table view
            avb.root.hash = newHash;
        }
    }

    pushUrl(avb.section, avb.thisYear, mode, avb.root.hash);

    if (pushurl) pushUrl(avb.section, avb.thisYear, mode, avb.root.hash);
    loadData();
}

/*
*   Year selection subroutines
*/

/*
* Switches visualizations to selected year
*
* @param {int} year - selected year
*
*/
// function changeYear(year) {
//     // don't switch if year is already selected
//     if (year === avb.thisYear) return;

//     // push change to browser history
//     pushUrl(avb.section, year, avb.mode, avb.root.hash);
//     // set new year values
//     avb.thisYear = year;
//     yearIndex = avb.thisYear - avb.firstYear;
//     // update navigation (treemap or table)
//     avb.navigation.update(avb.root);

//     avb.navigation.open(avb.currentNode.data.hash);
//     // remember year over page changes
//     $.cookie('year', year, {
//             expires: 14
//     });
//     // update homepage graph if needed
//     if ($('#avb-home').is(":visible")) {
//         avb.home.showGraph(100);
//     }
// }

/*
*   Helper functions
*/

/* As simple as that */
function log(d) {
    console.log(d);
}

/*
* Converts hex encoded color value to rgb
*
* @param {string} hex - hex color value
* @return {object} - rgb color object
*/
function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

/*
*   Mixes two rgb colors
*
*   @param {object} rgb1 - rgb color object
*   @param {object} rgb2 - rgb color object
*   @param {float} p - weight (0 to 1)
*   @return {rgb object} - mixed color
*/
function mixrgb(rgb1, rgb2, p) {
    return {
        r: Math.round(p * rgb1.r + (1 - p) * rgb2.r),
        g: Math.round(p * rgb1.g + (1 - p) * rgb2.g),
        b: Math.round(p * rgb1.b + (1 - p) * rgb2.b)
    };
}

/*
*   Mixes RGB color with white to give a transparency effect
*
*   @param {hex color} hex - color to which transparency has to be applied
*   @param {float} opacity - level of opacity (0.0 - 1.0 scale)
*   @return {rgba string} - rgba color with new transparency
*/
function applyTransparency(hex, opacity){
    var startRgb = mixrgb(hexToRgb(hex), {r:255, g:255, b:255}, opacity);
    return 'rgba(' + startRgb.r + ',' + startRgb.g + ',' + startRgb.b + ',' + 1.0 + ')';
}

/*
*   Applies translate to svg object
*/
function translate(obj, x, y) {
    obj.attr("transform", "translate(" + (x).toString() + "," + (y).toString() + ")");
}

/*
*  Centers object vertically
*/
$.fn.center = function () {
    // this.css("margin-top", Math.max(0, $(this).parent().height() - $(this).outerHeight()) / 2);
    return this;
};

/*
*   Resizes text to match target width
*
*   @param {int} maxFontSize - maxium font size
*   @param {int} targetWidth - desired width
*/
$.fn.textfill = function (maxFontSize, targetWidth) {
    var fontSize = 10;
    $(this).css({
        'font-size': fontSize
    });
    while (($(this).width() < targetWidth) && (fontSize < maxFontSize)) {
        fontSize += 1;
        $(this).css({
            'font-size': fontSize
        });
    }
    $(this).css({
        'font-size': fontSize - 1
    });

};

/*
*   Stops event propagation (on all browsers)
*
*   @param {event object} event - event for which propagation has to be stopped
*/
function stopPropagation(event){
    if(event) {
        event.cancelBubble = true;
        if(event.stopPropagation) event.stopPropagation();
    }
}

/*
*   Capitalizes a string
*
*   @param {string} string - string to be capitalized
*   @return {string} - capitalized string
*/
function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function findSection(hash){
    var section = null;
    $.each(avb.data, function(){
        if(findHash(hash, this) !== false) {
            section = this;
        }
    });
    return section;
}

/*
*   Finds node with given hash
*
*   @param {string} hash - hash to be searched
*   @param {node} node - current node
*   @return {node} - node with given hash
*/
function findHash(hash, node){
    var index = node.hash.indexOf(hash);
    // results
    if (index !== -1) return node;
    // propagate recursively
    if(node.sub !== undefined) {
        // propagate to all children
        for(var i=0; i<node.sub.length; i++) {
            var subResults = findHash(hash, node.sub[i]);
            if (subResults) return subResults;
        }
    }
    return false;
}

/*
File: custom.js

Description:
    Custom JS separate from the Visual Budget Framework

Authors:
    Bill Dean
*/

// Initialize SVG for Everybody
svg4everybody();  // eslint-disable-line no-undef

var avb = avb || {};

$( document ).ready(function () {
  // Initialize AVB (global function defined in avb.js)
  initialize();  // eslint-disable-line no-undef

  // Replace icon in navbar dropdown list
  (function (){
    $('#yeardrop').on('click', function (_event) {
      $('.yeardrop b').toggleClass('icon-chevron-down').toggleClass('icon-chevron-up');
    });

    // Update icon state when body is clicked
    $('html').click(function(e) {
      if (e.target.id != '#yeardrop' && $(e.target).parents('.yeardrop').length == 0) {
        $('.yeardrop b').addClass('icon-chevron-down').removeClass('icon-chevron-up');
      }
    });
  })();

  // Show Year dropdown and searchbox on data and home pages
  (function (){
    if (!$('.single').length) {
      $('.yeardrop, .search-container').show();
    }
  })();

  // Show/Hide search box
  (function () {
    var searchbox = $('#searchbox');
    var searchsubmit = $('#searchsubmit');
    var searchclose = $('#searchClose');
    var searchcontainer = $('.search-container');
    var logo = $('.logo');

    searchsubmit.on('click', function () {
      searchbox.add(searchclose).toggleClass('hide');

      if (!searchbox.hasClass('hide')){
        searchbox.focus();
      }

      if ($(window).width() < 1160) {
        searchcontainer.toggleClass('mobile-search-container');
        $('.mobile-menu, .mobile-close').toggleClass('search-margin');
        logo.toggleClass('hide');
      } else {
        searchbox.removeClass('mobile-search');
      }
    });

    // close search box
    searchclose.on('click', function () {
      $(this).add(searchbox).toggleClass('hide');
      if ($(window).width() < 1160) {
        searchcontainer.toggleClass('mobile-search-container');
        $('.mobile-close, .mobile-menu').toggleClass('search-margin');
        logo.toggleClass('hide');
      }
    });

    // Close searchbox on window resize
    var w = 0;
    // Virtual keyboard triggers window resize. Ensure search box is closed on horizontal window reisze only
    $( window ).load( function(){
      w = $( window ).width();
    });
    $(window).resize(checkWindowWidth);
    function checkWindowWidth() {
      var width = $( window ).width();
      if( w != $( window ).width() ) {
        if (width <= 1160) {
          // Close searchbox on window resize
          if (!searchbox.hasClass('hide')) {
            searchbox.addClass('hide');
            searchcontainer.removeClass('mobile-search-container');
            logo.removeClass('hide');
            searchclose.addClass('hide');
            $('.mobile-menu, .mobile-close').removeClass('search-margin');
          }
          if ($('.single').length) {
            $('.yeardrop').hide();
          }
        } else {
          $('.mobile-close').hide();
          $('#avb-home').css('z-index', '1010');
          if (!$('.single').length) {
            $('.yeardrop').show();
          }
          logo.removeClass('hide');
        }
        w = $( window ).width();
      }
    }
  })();

  // Show/hide navagation on mobile views
  (function () {
    var menu = $('.mobile-menu');
    var close = $('.mobile-close');
    var nav = $('#navbar-links');
    var overlay = $('#overlay');
    var homeBlock = $('#avb-home');
    var yearselectbox = $('.yeardrop');

    menu.on('click', function(){
      if (homeBlock.is(':visible')) {
        homeBlock.css('z-index', '0');
      } else {
        overlay.show();
      }
      $(this).hide();
      close.show();
      nav.show();
      yearselectbox.hide();
    });

    $(close).on('click', function() {
      $(this).hide();
      menu.show();
      nav.hide();
      if (homeBlock.is(':visible')) {
        homeBlock.css('z-index', '1010');
      } else {
        overlay.hide();
      }
      if ($('.single').length == 0) {
        yearselectbox.show();
      }
    });

    // Close mobile menu on window resize
    var w = 0;
    // Ensure mobile box is closed on horizontal window reisze only to allow horizontal scroll on mobile menu.
    $( window ).load( function(){
      w = $( window ).width();
    });
    $(window).resize(checkWindowWidth);
    function checkWindowWidth() {
      var width = $(window).width();

      if( w != $( window ).width() ) {
        if (width < 1160) {
          if ($('#navbar-links').is(':visible')) {
            $('#navbar-links').hide();
            $('.mobile-close').hide();
            $('.mobile-menu').show();
            $('.yeardrop').show();
            if (homeBlock.is(':visible')) {
              $('#avb-home').css('z-index', '1010');
            } else {
              overlay.hide();
            }
          }
        } else {
          if ($('.mobile-menu').is(':visible')) {
            $('.mobile-menu').hide();
          }
          $('#navbar-links').css('display', 'inline-block');
          if (!homeBlock.is(':visible')) {
            overlay.hide();
          }
        }
        w = $( window ).width();
      }
    }
  })();

  // Add selected class to nav on active pages. Note that Exp and Rev pages do not have href attributes.
  (function () {
    var navLinks = ['about-us', 'blog', 'glossary'];
    var slug = window.location.pathname.replace(/\//g,'');

    var selected = navLinks.find(function(navLink) {
      return navLink == slug;
    });

    if (selected) {
      $('.navbar-container span a[href^="/' + selected + '"]').parent().addClass('selected');
    }
  })();

  (function() {
    // Update nav links based on year selected
    $('.menu .expense a').attr('href', '/expenses' + avb.thisYear);
    $('.menu .expense').attr('data-section', 'expenses' + avb.thisYear);
    $('.menu .revenue a').attr('href', '/revenues' + avb.thisYear);
    $('.menu .revenue').attr('data-section', 'revenues' + avb.thisYear);
    $('#yeardrop option[value=' + avb.thisYear + ']').attr('selected','selected');

    var selectYear;
    var section;
    $( '#yeardrop' ).change(function() {
      selectYear = $('#yeardrop option:selected').val();

      if ($('#avb-home').is(':visible')) {
        // Update values on homepage based on year in dropdown
        var expSection = 'expenses' + selectYear;
        var revSection = 'revenues' + selectYear;

        $('#revenues-node').find('.node-value').text(toPersianNum(formatcurrency(avb.data[revSection].values[0].val))); // eslint-disable-line no-undef
        $('#expenses-node').find('.node-value').text(toPersianNum(formatcurrency(avb.data[expSection].values[0].val))); // eslint-disable-line no-undef

        // change links of home sections
        $('.home-content span.exp a').attr('href', '/' + expSection);
        $('.home-content span.rev a').attr('href', '/' + revSection);

      } else {
        // Open current section of year selected
        section = avb.section.split('1')[0];
        // window.location = '/' + section + selectYear;
        window.location = '/' + section + selectYear + '/' + selectYear + '/' + avb.mode + '/' + avb.root.hash;
      }
    });
  })();

  // FAQ Show/hide
  (function (){
    $('.q').append('<span><svg><use xlink:href="/img/icons-d41d8cd98f.svg#arrow-down"></use></svg></span><span class="hidden"><svg><use xlink:href="/img/icons-d41d8cd98f.svg#arrow-up"></use></svg></span>');
    $('.q').click(function() {
      $(this).children('span').toggleClass('hidden');
      $(this).next('.a').slideToggle();
      $(this).toggleClass('q-border');
    });
  })();

  // create trigger to resizeEnd event
  (function (){
    $(window).resize(function() {
      if(this.resizeTO) clearTimeout(this.resizeTO);
      this.resizeTO = setTimeout(function() {
        $(this).trigger('resizeEnd');
      }, 500);
    });

    // redraw graph when window resize is completed
    $(window).on('resizeEnd', function() {
      var treemap = $('#navigation svg');
      treemap.css('width', '100%');
      $('#navigation').css('height', 'auto');
    });
  })();

});

// Helper function to convert numerical data to Persian numbers
function toPersianNum( num, dontTrim ) {
  var i = 0,

    dontTrim = dontTrim || false,

    num = dontTrim ? num.toString() : num.toString().trim(),
    len = num.length,

    res = '',
    pos,

    persianNumbers = typeof persianNumber == 'undefined' ?
      ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'] :
      persianNumbers;

  for (; i < len; i++)
    if (( pos = persianNumbers[num.charAt(i)] ))
      res += pos;
    else
      res += num.charAt(i);

  return res;
}
